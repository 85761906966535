import React from 'react'
import CardDestination from '../../components/CardDestination'
import TittleSection from '../../components/TittleSection'

import { useContext, useEffect } from 'react';

import { TravelsContext } from "../../Api/Travels"






function Destination() {

    const { cityTravels } = useContext(TravelsContext)


    let TitlePageObject = {
        title: "Destination",
        subTittle: "Destination in egypt",
        description: "Discover unforgettable adventures with Elite Destinations, where exceptional service ensures lasting memories. Welcome to unparalleled destinations!" 
    }



    return (

        <section>
            <div className='container' >
                <TittleSection TitlePageObject={TitlePageObject} />
                <div className='grid-style' >
                    {
                        cityTravels.map((item) => (
                            <CardDestination data={item} key={item._id} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Destination