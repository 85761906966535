import React from 'react'
import Header from '../components/Header';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { TravelsContext } from "../Api/Travels"
import CardProject from '../components/CardProject';

import Search from '../components/Search';
import Footer from '../components/Footer';


function ProjectsSearch() {

  const { travels } = useContext(TravelsContext)
  const { category } = useParams();



  return (
    <>
      <Header />
      <div className='project-page first-section' >
        <div className='container' >
          <Search categorySearch={category} />
          <div className='grid-style'>
            {
              travels.map((travel) => (
                <CardProject travel={travel} key={travel._id} />
              ))
            }
          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default ProjectsSearch