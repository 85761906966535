import React from 'react'

function TittleSection({TitlePageObject}) {

    let { title, subTittle, description } = TitlePageObject


    return (
        <div className='tittle-section' >
            <p className='p-section-name'>{title}</p>
            <h2>{subTittle}</h2>
            <p>{description}</p>
        </div>
    )
}

export default TittleSection