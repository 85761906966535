import React, { useState } from 'react';
import axios from 'axios';
import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';

function Gallery() {
    const BASE_URL = process.env.REACT_APP_API_KEY;
    const [gallery, setGallery] = useState([]);
    const [errorAlertMessage, setErrorAlertMessage] = useState('');
    const [successAlertMessage, setSuccessAlertMessage] = useState('');
    const [toggleAlertError, setToggleAlertError] = useState(false);
    const [toggleAlertSuccess, setToggleAlertSuccess] = useState(false);

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        // Ensure unique files using a Set
        const uniqueFiles = new Set(selectedFiles);
        setGallery([...uniqueFiles]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        gallery.forEach((image) => formData.append('images', image));

        try {
            const response = await axios.post(`${BASE_URL}/api/gallery`, formData);

            if (response.data.error) {
                setErrorAlertMessage(response.data.error);
                setToggleAlertError(true);
            } else {
                setSuccessAlertMessage(response.data.success);
                setToggleAlertSuccess(true);
                setGallery([]); // Clear gallery after successful upload
            }
        } catch (error) {
            console.error('Error during upload:', error);
            setErrorAlertMessage('فشل في رفع الصور');
            setToggleAlertError(true);
        } finally {
            setTimeout(() => {
                setToggleAlertError(false);
                setToggleAlertSuccess(false);
            }, 5000);
        }
    };

    return (
        <>
            <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
            <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSuccess} />
            <div className="gallery-page first-section">
                <div className="container">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="drag-file-area">
                            <span className="material-icons-outlined upload-icon">رفع الصور</span>
                            <label className="label">
                                <span className="browse-files">
                                    <input type="file" name="images" required onChange={handleFileChange} multiple className="default-file-input" />
                                    <span className="browse-files-text">تحميل الصور</span>
                                    <span>اختار الملافات </span> </span>
                            </label>
                        </div>
                        <div className='img-upload-div' >
                            {gallery.map((image, index) => (
                                <div key={index} className='slide-img-upload' >
                                    <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                        <input className='button' type='submit' value="اضافه الرحله" />
                    </form>
                </div>
            </div>
        </>
    );
}

export default Gallery;
