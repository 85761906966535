import React from 'react'
import aboutImg from "../../assets/elements/bk.jpg"

import safetyIcon from "../../assets/icons/Shield Check wight.svg"
import usersGroupIcon from "../../assets/icons/Users Group Two Rounded wight.svg"
import medalRibbonsStarIcon from "../../assets/icons/Medal Ribbons Star.svg"
import starIcon from "../../assets/icons/Star wight.svg"


function About() {
    return (
        <section className='about-section' >
            <div className='container' >
                <div className='grid-style-tow' >
                    <img className='img-size' alt='about-img' src={aboutImg} />
                    <div className='side-div-grid' >
                        <p className="p-section-name">About Company</p>
                        <h2>
                        Excellence in Travel: Safety, Expertise, and Tailored Experiences
                        </h2>
                        <div className='icon-about-side'>
                            <div className='icon-div-text' >
                                <div className='icon-div'>
                                    <img alt='price cheap Icon' src={safetyIcon} />
                                </div>
                                <div className='div-text' >
                                    <h3>Safety first</h3>
                                    <p>Your safety is our priority. We adhere to the highest safety standards, ensuring worry-free travel.</p>
                                </div>
                            </div>
                            <div className='icon-div-text' >
                                <div className='icon-div'>
                                    <img alt='price cheap Icon' src={usersGroupIcon} />
                                </div>
                                <div className='div-text' >
                                    <h3>Expert Team</h3>
                                    <p>Our experienced professionals are committed to delivering excellence, from the moment you book until you reach your destination.</p>
                                </div>
                            </div>
                            <div className='icon-div-text' >
                                <div className='icon-div'>
                                    <img alt='price cheap Icon' src={medalRibbonsStarIcon} />
                                </div>
                                <div className='div-text' >
                                    <h3>Premium Fleet</h3>
                                    <p>Our modern and diverse fleet ensures comfort, safety, and style throughout your journey.
                                    </p>
                                </div>
                            </div>
                            <div className='icon-div-text' >
                                <div className='icon-div'>
                                    <img alt='price cheap Icon' src={starIcon} />
                                </div>
                                <div className='div-text' >
                                    <h3>Tailored Experiences</h3>
                                    <p>Whether it's a corporate event, a family vacation, or a solo adventure, we tailor our services to meet your unique needs.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default About