import React from 'react'
import TitleSection from '../../components/TitleSection'



import Card from './components/Card'

function OurStatistics({ourStatisticsObject}) {

    let { TitleObjectExplain, cards } = ourStatisticsObject
    return (
        <section>
            <div className='container our-statistics container-scroll' id='services'>
                <TitleSection TitleObject={TitleObjectExplain} />
                <div className="services-grid grid-style" >
                    {
                        cards.map((item) => {
                            return (
                                <Card key={item.id} data={item} />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default OurStatistics