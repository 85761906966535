import React from 'react'

import ContactUs from '../sections/contact-us-section/ContactUs'

import Header from '../components/Header'
import Footer from '../components/Footer'

import OurStatistics from "../components/our-statistics/OurStatistics"


import phone from "../assets/icons/phone.svg"
import email from "../assets/icons/envelope.svg"
import map from "../assets/icons/map.svg"


function Contact() {



  let ourStatisticsObject = {
    TitleObjectExplain: {
        titleSection: "Our Statistics",
        titleMain: "The Most important services",
        titleGradient: "we provide",
        description: "With our integrated CRM, project management, collaborationand invoicing capabilities, you can manageyour business in one secure platform.",
    },
    cards: [{
        title: "HotLine",
        description: "+20 111 855 4084",
        img: phone
    }, {
        title: "Email Address",
        description: "eliteetravellagenvyy@gmail.com",
        img: email
    }, {
        title: "Office Location",
        description: "Egypt - Red Sea, Hurghada City, Elkawther",
        img: map
    }
    ]
}

  return (
    <>
      <Header />
      <section className='first-section'>
        <OurStatistics ourStatisticsObject={ourStatisticsObject} />

        <ContactUs />
      </section>
      <Footer />
    </>

  )
}

export default Contact