import React from 'react'
import imgProject from "../assets/elements/img.jpg"
import arrowIcon from "../assets/icons/arrow.svg"
import destinationIcon from "../assets/icons/destination.svg"
import { Link } from 'react-router-dom'



function CardDestination({ data }) {


    let { cover, name, _id } = data


    return (
        <div className='card-destination' >
            <Link to={`/projects/${name}`}>
                <img alt='img-destination' className='img-destination' src={cover} />

                <div className='tittle-div-arrow' >
                    <h2>{name}</h2>
                    <img alt='arrow-icon' className='arrow-icon' src={arrowIcon} />
                </div>

                
            </Link>
        </div>
    )
}

export default CardDestination