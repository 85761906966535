import React from 'react'
import { useContext , useState, useEffect } from 'react';

import { ContactApiContext } from "../../../Api/ContactApi"

import MessageCard from "./contactComp/MessageCardDone"

import axios from 'axios'

import ErrorAlert from '../../../components/alertCopm/ErrorAlert';
import SuccessAlert from '../../../components/alertCopm/SuccessAlert'


function ContactTravels() {
    const { contactTravels } = useContext(ContactApiContext)

  
    let BASE_URL = process.env.REACT_APP_API_KEY

    let [successAlertMessage, setSuccessAlertMessage] = useState("")
    let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)

    let [errorAlertMessage, setErrorAlertMessage] = useState("")
    let [toggleAlertError, setToggleAlertError] = useState(false)


    let [dataMessage, setDataMessage] = useState([])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/contact`).then((response) => {
            let data = response.data

            let dataFilter = data.filter((data) => data.contactDone)

            setDataMessage(dataFilter.reverse());
        })
    }, [])



    let [updateUi, setUpdateUi] = useState(false)


    useEffect(() => {
        axios.get(`${BASE_URL}/api/user`)
           
    }, [updateUi]);




    return (
        <div className='content-dashboard' >
            <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
            <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />
            <div className='grid-style' >

                {
                    dataMessage.map((data) => {
                        return (
                            <MessageCard data={data} key={data._id} setUpdateUi={setUpdateUi}
                                updateUi={updateUi}

                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setToggleAlertSucsses={setToggleAlertSucsses}
                                setErrorAlertMessage={setErrorAlertMessage}
                                setToggleAlertError={setToggleAlertError}

                            />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default ContactTravels