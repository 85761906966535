import React from 'react'
import { NavLink } from 'react-router-dom'

import logo2 from "../../assets/logo/logo.svg"

import mapIcon from "../../assets/icons/Map Arrow Square.svg"

function SideBar() {
  return (
    <div className="nav-par-side">
      <NavLink to="/" className="a-logo a-logo-dashboard">
        <img src={logo2} alt="logo.png" />
      </NavLink>
      <ul className="nav-sec nav-home-sec">
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/projects`} >
            <div className='list-icon' >
         
              اضافه رحله
            </div>

          </NavLink>

        </li>
        <li>

          <NavLink aria-label="Nav Link" to={`/admin/update-projects`} >
            <div className='list-icon' >
              
              تعديل الرحله
            </div>

          </NavLink>
        </li>

        <li>
          <NavLink aria-label="Nav Link" to={`/admin/add-city-travels`} >
            <div className='list-icon' >
              
              اضافه مدينه رحلات
            </div>

          </NavLink>
        </li>
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/delete-city-travels`} >
            <div className='list-icon' >
            
              حذف مدينه رحلات
            </div>

          </NavLink>
        </li>
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/contact-travels`} >
            <div className='list-icon' >
           
              الرحلات العملاء
            </div>

          </NavLink>
        </li>
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/contact-travels-done`} >
            <div className='list-icon' >
          
              الرحلات العملاء تمت
            </div>

          </NavLink>
        </li>
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/gallery`} >
            <div className='list-icon' >
          
           اضافه صور
            </div>

          </NavLink>
        </li>
        <li>
          <NavLink aria-label="Nav Link" to={`/admin/gallery-delete`} >
            <div className='list-icon' >
      
             حذف صور 
            </div>

          </NavLink>
        </li>


      </ul>
    </div>
  )
}

export default SideBar 