
import React from 'react'

import TitleSection from '../components/TitleSection'

import img from "../assets/elements/img.jpg"

function AchievementNew() {
    let TitleObject = {
        titleSection: "About Company",
        titleMain: "Discover Our Recent Performance in a ",
        titleGradient: "Few Easy Steps.",
        description: "Explore our project history and the security vulnerabilities uncovered by our team over the years",
    }




    return (
        <section>
            <div className="container container-scroll" id='achievement'>

                <TitleSection TitleObject={TitleObject} />

                <div className='grid-style-tow' >
                <img src={img} alt='img-about-us' className='img-size' />
                    
                    <div className="explain-text" >
                        <h2  >
                        Discover our achievements in Elite Travel Agency:

                        </h2>
                        <div className='grid-achievement' >
                            <div>
                                <h3>500+</h3>
                                <p>CLients</p>
                            </div>
                            <div>
                                <h3>70+</h3>
                                <p>Excursions</p>
                            </div>
                            <div>
                                <h3>10+</h3>
                                <p>Experience Years</p>
                            </div>
                          

                        </div>

                    </div>

                </div>


            </div>
        </section>
    )
}

export default AchievementNew