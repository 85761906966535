import React, { useState, useEffect } from 'react';


import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";


import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';


import Header from '../components/Header';
import Footer from '../components/Footer';


import userSvg from "../assets/icons/user (2).svg"
import phoneSvg from "../assets/icons/phone.svg"
import emailSvg from "../assets/icons/envelope.svg"
import messageSvg from "../assets/icons/message-dots.svg"
import countrySvg from "../assets/icons/Globus.svg"
import starSvg from "../assets/icons/Star.svg"
import numberOfPersonSvg from "../assets/icons/Users Group Two Rounded.svg"
import roomNumberSvg from "../assets/icons/Slider Vertical.svg"


import SuccessAlert from '../components/alertCopm/SuccessAlert';
import ErrorAlert from '../components/alertCopm/ErrorAlert';







function ProjectPage() {


  

  let BASE_URL = process.env.REACT_APP_API_KEY;
  const { id } = useParams();
  let [travelDetails, setTravelDetails] = useState({});

  let { _id, price, discount, name, palace, about, category, categoryPlace, images, location, timeTravel } = travelDetails


  const categoryArray = category?.split(",");

  let [loading, setLoading] = useState(true);

 


  //  Form 

  let [errorAlertMessage, setErrorAlertMessage] = useState("")
  let [successAlertMessage, setSuccessAlertMessage] = useState("")
  let [toggleAlertError, setToggleAlertError] = useState(false)
  let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)

  const [fullName, setFullName] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [emailAddress, setGmail] = useState("")
  const [hotelStar, setHotelStar] = useState("")
  const [numberOfPerson, setNumberOfPerson] = useState("")
  const [roomNumber, setRoomNumber] = useState("")
  const [message, setMessage] = useState("")

  const [locationHref, setLocationHref] = useState(window.location.href)
  const [nameTravel, setNameTravel] = useState("")



  let addMessage = (e) => {
    e.preventDefault()
    axios.post(`${BASE_URL}/api/contact`, { fullName, phone, country, emailAddress, hotelStar, numberOfPerson, roomNumber, message , locationHref , nameTravel }).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error)
        setToggleAlertError(true)
        setTimeout(() => {
          setToggleAlertError(false)
        }, 5000)
      } else {
        setSuccessAlertMessage(response.data.success)
        setToggleAlertSucsses(true)
        setTimeout(() => {
          setToggleAlertSucsses(false)
        }, 5000)
        setFullName("")
        setPhone("")
        setGmail("")
        setMessage("")
        setCountry("")
        setHotelStar("")
        setNumberOfPerson("")
        setRoomNumber("")
      }

    })
  }

  useEffect(() => {
    axios.get(`${BASE_URL}/api/travel/${id}`)
      .then((response) => {
        setTravelDetails(response.data);
        setLoading(false); 
        setNameTravel(response.data.name)
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        setLoading(false); 
      });
     
  }, []);


  return (

    <>
      <Header />
      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />
      <div className='project-page first-section'>
        <div className="container">
          <div className="grid-project-div">
            <div className="main-contact-column">
              <div className="div-39">
                <div className="div-40">
                  <div className="div-41">
                    <div className="gird-img-project-details">
                      {/* <img loading="lazy" src={images && images.length > 0 ? `${BASE_URL}/uploads/travel/${images[0]}` : ''} className="img-7" /> */}
                      <img src={images && images.length > 0 ? images[0] : ''} className="img-7" alt='img-Travels' />

                      
                      <div className='slide-image-details'>
                        {!loading ? (
                          <Swiper
                            spaceBetween={5}
                            slidesPerView={1}
                            breakpoints={{
                              50: { slidesPerView: 3 },
                              576: { slidesPerView: 3 },
                              1000: { slidesPerView: 3 },
                              1500: { slidesPerView: 3 },
                            }}
                          >
                            {images && images.splice(1).map((item, index) => (
                              <SwiperSlide key={index}>
                                <div className="div-img-project-details">
                                  <img alt={`img-project-${index}`} src={item} className='img-10' />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>

                    </div>
                    <div className="div-details-text-name">
                      <div>
                        <span >
                          Price Travels
                          <br />
                        </span>
                        <div className='div-discount' >
                          Start From
                          <b>
                            {` ${price || ""} `}
                          </b>
                          / <del className='discount discount-page'>{discount || ""} </del>
                        </div>

                      </div>
                      <h1 className="h1-page-title">
                        {name}
                      </h1>
                      <div className="div-category-details">

                        {categoryArray && categoryArray.map((item) => (
                          <div className="div-category" key={item}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='content-travle-details' >
                  <div dangerouslySetInnerHTML={{ __html: about }} className='div-content ' />

                </div>
              </div>
            </div>
            <div className="side-contact-column">


              <div className="div-side">

                <div className='form-container' data-aos="fade-up" data-aos-duration="1200">
                  <form onSubmit={(e) => addMessage(e)} >
                    <div className='name-phone-div' >
                      <div className='div-img-input'>
                        <img src={userSvg} alt='icon-contact' />
                        <input  type="text" placeholder="name" required value={fullName} onChange={(e) => setFullName(e.target.value)} />
                      </div>
                      <div className='div-img-input'>
                        <img src={phoneSvg} alt='icon-contact' />
                        <PhoneInput country={'ca'} enableSearch={true} required value={phone} onChange={(phone) => setPhone(phone)} />
                      </div>
                    </div>
                    <div className='div-img-input'>
                      <img src={emailSvg} alt='icon-contact' />
                      <input type="email" placeholder="email" required value={emailAddress} onChange={(e) => setGmail(e.target.value)} />
                    </div>
                    <div className='div-img-input'>
                      <img src={countrySvg} alt='icon-contact' />
                      <input type="text" placeholder="country" required value={country} onChange={(e) => setCountry(e.target.value)} />
                    </div>
                    <div className='div-img-input'>
                      <img src={starSvg} alt='icon-contact' />
                      <input type="number" placeholder="hotel star" required value={hotelStar} onChange={(e) => setHotelStar(e.target.value)} />
                    </div>
                    <div className='div-img-input'>
                      <img src={numberOfPersonSvg} alt='icon-contact' />
                      <input type="number" placeholder="number Of Person" required value={numberOfPerson} onChange={(e) => setNumberOfPerson(e.target.value)} />
                    </div>
                    <div className='div-img-input'>
                      <img src={roomNumberSvg} alt='icon-contact' />
                      <input type="number" placeholder="room Number" required value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} />
                    </div>
                    <div className='div-img-input textarea-div'>
                      <img src={messageSvg} alt='icon-contact' />
                      <textarea placeholder="your message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                    <button type="submit">Book Now</button>
                  </form>
                </div>

              </div>
              {location &&
                <div className="div-side">
                  <p className="text-p-side">
                    Location
                  </p>
                  <div className="google-map-code">
                    <iframe

                      src={location}
                      width="600"
                      height="450"
                      frameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      title="Embedded Location"
                    ></iframe>


                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default ProjectPage