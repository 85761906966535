import React from 'react'
function Card({ data }) {

  let { img, title, description } = data


  return (
    <div className='card'>
      <div className="counter"> <img src={img} alt="img" className='icon' />
      </div>
      <div className="content-card">

        <h2  >{title}</h2>
        <p  >
          {description}
        </p>
      </div>
    </div>
  )
}

export default Card