
import './App.css';

import { Routes, Route } from "react-router-dom";

import Home from "./page/Home"
import Projects from './page/Projects';
import ProjectsSearch from './page/ProjectsSearch';
import ProjectPage from './page/ProjectPage';
import Destination from './page/Destination';
import About from './page/About';
import Contact from './page/Contact';
import Gallery from './page/Gallery';

import Dashboard from './page/Dashboard';
import Login from "./admin/pages/Login"

import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { useCookies } from "react-cookie"

import WhatsappIconButton from './components/WhatsappIconButton';

function App() {

  const [cookies, setCookie] = useCookies(['access_token']);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    })



  }, [location.pathname])


  return (
    <div className="App">

      <WhatsappIconButton/>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:category" element={<ProjectsSearch />} />
        <Route path="/project-page/:id" element={<ProjectPage />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />

        

        <Route path="/admin/*" element={cookies.access_token ? <Dashboard /> : <Login />} />
        <Route path="/login" element={ cookies.access_token ? <Dashboard />  :  <Login />}  />
      </Routes>


    </div>
  );
}

export default App;
