import React from 'react'

import axios from 'axios'
import { createContext, useEffect, useState } from 'react'

export const GalleryApiContext = createContext(null)


function GalleryApi(props) {


    let BASE_URL = process.env.REACT_APP_API_KEY
    let [gallery, setGallery] = useState([])

    useEffect(() => {
        axios.get(`${BASE_URL}/api/gallery`).then((response) => {
            let data = response.data;
            setGallery(data);
        });

    }, []);

    return (
        <GalleryApiContext.Provider value={{ gallery }}>
            {props.children}
        </GalleryApiContext.Provider>
    )
}

export default GalleryApi