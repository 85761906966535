import React from 'react'

import imgProject from "../assets/project-img/pexels-photo-13297062 1.jpg"
import MapIcon from "../assets/icons/MapPoint.svg"
import ClockIcon from "../assets/icons/Clock.svg"
import UserIcon from "../assets/icons/User.svg"

import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';



function CardProject({ travel }) {

    let BASE_URL = process.env.REACT_APP_API_KEY
    let { name, palace, timeTravel, images, _id, price, discount } = travel

    return (


        <div className='card'>

            {  price && <div className='price'><p className='price-p' >{price}</p> /  <p className='discount'>  {discount} </p></div>   }

            <Swiper
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                    50: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 1,
                    },
                    1000: {
                        slidesPerView: 1,
                    },
                    1500: {
                        slidesPerView: 1,
                    },
                }}
            >
                {
                    images.map((item , index) => {
                        return (
                            <SwiperSlide key={item._id}>
                                
                                <div className="card-projects"   >
                                    {/* <img alt='img-project' src={`${BASE_URL}/uploads/travel/${item}`} /> */}
                                    <img alt='img-project' src={item} />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <div className='div-card-tittle-place' >
                <h1>
                    <Link to={`/project-page/${_id}`} >
                        {name}
                    </Link>
                </h1>
                <div className='place-project-card flex-div-5'>
                    <img alt='map-icon' src={MapIcon} className='icon' />
                    <p>{palace}</p>
                </div>
            </div>
            {
                timeTravel && <div className='details-card' >
                    <div className='icon-details' >
                        <img alt='map-icon' src={ClockIcon} className='icon' />
                        <p>{timeTravel}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default CardProject