import React from 'react'

import { Routes, Route } from "react-router-dom";


import SideBar from '../admin/components/SideBar';

import AddNewTravle from "../admin/pages/travels/AddNewTravel"

import UpdateDeleteTravel from '../admin/pages/travels/UpdateDeleteTravel';

import UpdateTravele from '../admin/pages/travels/UpdateTravele';

import AddCityTravels from '../admin/pages/city-travels/AddCityTravels';
import DeleteCityTravels from '../admin/pages/city-travels/DeleteCityTravels';

import ContactTravels from '../admin/pages/contact/ContactTravels';
import ContactTravelsDone from '../admin/pages/contact/ContactTravelsDone';

import GalleryDelete from '../admin/pages/gallary/GalleryDelete';
import Gallery from '../admin/pages/gallary/Gallery';

function Dashboard() {
  return (
    <div dir='rtl' >



      <div className='grid-dashboard' >


        <SideBar />
        <div className='container-dashboard' >
          <Routes>
            <Route path="/projects" element={<AddNewTravle />} />
            <Route path="/update-projects" element={<UpdateDeleteTravel />} />
            <Route path="/update-projects/:id" element={<UpdateTravele />} />
            <Route path="/add-city-travels" element={<AddCityTravels />} />
            <Route path="/delete-city-travels" element={<DeleteCityTravels />} />
            <Route path="/contact-travels" element={<ContactTravels />} />
            <Route path="/contact-travels-done" element={<ContactTravelsDone />} />

            <Route path="/gallery-delete" element={<GalleryDelete />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </div>

      </div>

    </div>
  )
}

export default Dashboard