import React from 'react'

import { useContext } from 'react';

import TittleSection from '../../components/TittleSection'
import CardProject from '../../components/CardProject'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';

import { TravelsContext } from "../../Api/Travels"



function ProjectSection() {


    const {travelsHome} = useContext(TravelsContext)

    let TitlePageObject = {
        title: "Elite Travel",
        subTittle: "Trending Excursions",
        description: "Discover seamless travel with Elite Travel, where each journey is an unforgettable experience. Our commitment to exceptional service and passion for exploration ensures lasting memories. Welcome to your gateway to unparalleled journeys!"
    }

    

    return (
        <section>

            <div className='container' >
                <TittleSection TitlePageObject={TitlePageObject} />

                <div  >
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        breakpoints={{
                            50: {
                                slidesPerView: 1,
                            },
                            576: {
                                slidesPerView: 2,
                            },
                            1000: {
                                slidesPerView: 3,
                            },
                            1500: {
                                slidesPerView: 3,
                            },
                        }}

                    >


                        {
                            travelsHome.map((travel) => (
                                <SwiperSlide key={travel._id}>
                                    <CardProject travel={travel} />
                                </SwiperSlide>
                            ))
                        }





                    </Swiper>

                </div>
            </div>

        </section>
    )
}

export default ProjectSection