import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'


import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';


function AddNewTravle() {
  let BASE_URL = process.env.REACT_APP_API_KEY








  let [name, setName] = useState("")
  let [palace, setPalace] = useState("")
  let [price, setPrice] = useState("")
  let [discount, setDiscount] = useState("")
  let [timeTravel, setTimeTravel] = useState("")
  let [location, setLocation] = useState("")
  let [categoryPlace, setCategoryPlace] = useState("")
  let [privates, setPrivate] = useState(false)
  let [category, setCategory] = useState("")
  let [images, setImages] = useState([])

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);

    setImages(filesArray);
  };

  let [about, setAbout] = useState("")




  let [errorAlertMessage, setErrorAlertMessage] = useState("")
  let [successAlertMessage, setSuccessAlertMessage] = useState("")
  let [toggleAlertError, setToggleAlertError] = useState(false)
  let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)









  let postButton = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', name);
    formData.append('palace', palace);
    formData.append('price', price);
    formData.append('discount', discount);
    formData.append('timeTravel', timeTravel);
    formData.append('location', location);
    formData.append('categoryPlace', categoryPlace);
    formData.append('category', category);
    formData.append('privates', privates);
    formData.append('about', about);

    images.forEach((image, index) => {
      formData.append('images', image);
    });

    axios.post(`${BASE_URL}/api/add-new-travel`, formData).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error)
        setToggleAlertError(true)
        setTimeout(() => {
          setToggleAlertError(false)
        }, 5000)
      } else {
        setSuccessAlertMessage(response.data.success)
        setToggleAlertSucsses(true)
        setTimeout(() => {
          setToggleAlertSucsses(false)
        }, 5000)

      }
    });
  }







  return (
    <div className='add-new-container add-new-travel' >


      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />

      <form onSubmit={postButton} encType="multipart/form-data">

        <div className='add-new-tittle' >



          <div className="drag-file-area">
            <span className="material-icons-outlined upload-icon">رفع الصور</span>
            <label className="label">

              <span className="browse-files">
                <input required type="file" name="images" onChange={handleFileChange} multiple className="default-file-input" />

                <span className="browse-files-text">تحميل الصور</span>
                <span>اختار الملافات </span> </span>
            </label>

          </div>




          <div className='img-upload-div' >
            {images.map((image, index) => (
              <div key={index} className='slide-img-upload' >
                <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </div>




        </div>



        <div className='add-new-tittle' >
          <h2>اسم الرحله</h2>
          <input required type='text' placeholder='ادخل اسم الرحله' value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='add-new-tittle multer-add-new' >
          <div className='input-div' >
            <h2> سعر الرحله</h2>
            <input  type='text' placeholder="ادخل سعر الرحله = € 50 " value={price} onChange={(e) => setPrice(e.target.value)} />
          </div>
          <div className='input-div' >
            <h2> سعر قبل الخصم</h2>
            <input type='text' placeholder='ادخل سعر الحرحله قبل الخصم' value={discount} onChange={(e) => setDiscount(e.target.value)} />
          </div>
        </div>

        <div className='add-new-tittle' >
          <h2> عنوان الرحله</h2>
          <input required type='text' placeholder='ادخل عنوان الرحله' value={palace} onChange={(e) => setPalace(e.target.value)} />
        </div>

        <div className='add-new-tittle' >
          <h2> مده الحرله</h2>
          <input type='text' placeholder='ادخل مده الرحله = 3 Days' value={timeTravel} onChange={(e) => setTimeTravel(e.target.value)} />
        </div>

        <div className='add-new-tittle' >
          <h2> رابط عنوان الرحله</h2>
          <input type='text' placeholder='ادخل رابط الرحله علي الـ map' value={location} onChange={(e) => setLocation(e.target.value)} />
        </div>

        <div className='add-new-tittle'  >
          <h2> مدينه الرحله</h2>
          <input required type='text' placeholder='ادخل مدينه الرحله = Giza' value={categoryPlace} onChange={(e) => setCategoryPlace(e.target.value)} />
        </div>
        <div className='add-new-tittle' >
          <h2> نوع الرحله</h2>
          <select id="select" value={privates} onChange={(e) => setPrivate(e.target.value)}>
            <option value="false">public</option>
            <option value="true">private</option>
          </select>
        </div>

        <div className='add-new-tittle' >
          <h2> كاتوجري الرحله</h2>
          <input type='text' placeholder='ادخل كاتوجري معبره عن الرحله = pyramids , sand...' value={category} onChange={(e) => setCategory(e.target.value)} />
        </div>


        <div className='add-new-tittle' >
          <h2> تفاصيل عن الرحله</h2>
          <ReactQuill required value={about} onChange={setAbout} />
        </div>

        <input className='button' type='submit' value="اضافه الرحله" />

      </form>


    </div>
  )
}

export default AddNewTravle