import React, { useState , useContext } from 'react';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';

import Select from 'react-select';
import { TravelsContext } from "../../../Api/Travels"


function AddCityTravels() {
  const BASE_URL = process.env.REACT_APP_API_KEY;

  const [name, setName] = useState("");
  const [cover, setCover] = useState(null);

  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [toggleAlertError, setToggleAlertError] = useState(false);
  const [toggleAlertSuccess, setToggleAlertSuccess] = useState(false);

  const handleFileChange = (e) => {
    setCover(e.target.files[0]);
  };

  const postButton = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', cover);

      const response = await axios.post(`${BASE_URL}/api/add-new-city-travels`, formData);

      if (response.data.error) {
        setErrorAlertMessage(response.data.error);
        setToggleAlertError(true);
        setTimeout(() => {
          setToggleAlertError(false);
        }, 5000);
      } else {
        setSuccessAlertMessage(response.data.success);
        setToggleAlertSuccess(true);
        setTimeout(() => {
          setToggleAlertSuccess(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error while posting data:", error);
      setErrorAlertMessage("حدث خطأ أثناء إضافة الرحلة");
      setToggleAlertError(true);
      setTimeout(() => {
        setToggleAlertError(false);
      }, 5000);
    }
  };


  const [selectedOption, setSelectedOption] = useState(null);
  const { categoryPlace, setCategoryPlaceString } = useContext(TravelsContext)


  const optionsPlace = categoryPlace.map(place => ({
    value: place,
    label: place,
  }));

  return (
    <div className='add-new-container add-new-travel'>
      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSuccess} />

      <form onSubmit={postButton} encType="multipart/form-data">
        <div className='add-new-tittle'>
          <div className="drag-file-area">
            <span className="material-icons-outlined upload-icon">رفع الصور</span>
            <label className="label">
              <span className="browse-files">
                <input
                  type="file"
                  onChange={handleFileChange}
                  required
                  name="image"
                  className="default-file-input"
                />
                <span className="browse-files-text">تحميل الصور</span>
                <span>اختار الملفات </span>
              </span>
            </label>

          </div>
        </div>
           
         

        <div className='img-upload-div'>
          {cover && (
            <div className='slide-img-upload'>
              <img src={URL.createObjectURL(cover)} alt="Image Upload" />
            </div>
          )}
        </div>




        <div className='add-new-tittle'>
        <div className='select-div-parent' >
              <div className='div-select'>
                <div className='icon-select'>

                  <p>Place Locations</p>
                  <Select
                  defaultValue={selectedOption}
                  onChange={(e) => setName(e.value)}
                  options={optionsPlace}
                  placeholder="Select Place"
                />
                </div>
              </div>

          </div>
          
          {/* <h2>اسم الرحلة</h2>
          <input type='text' placeholder='ادخل اسم الرحلة' value={name} onChange={(e) => setName(e.target.value)} /> */}
        </div>

        <input className='button' type='submit' value="اضافة الرحلة" />
      </form>
    </div>
  );
}

export default AddCityTravels;
