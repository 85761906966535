import React from 'react'
import backgroundImg from "../../assets/elements/bk.jpg"

import Search from '../../components/SearchHome.js'


function HeroSection() {
    return (

        <main className="main-sec sec first-section">
            <div className="container">
                <div className='main-contact'>
                    <div className="h1-text-div">

                        <div className='flex-div-column-20' >
                            <div className='flex-div-column-10'>
                                <h2  >Your First Place
                                    <br />
                                    For Tourism in Egypt
                                    <br />
                                    Buy the best
                                </h2>
                            </div>

                            <div className='flex-achievement' >
                                <div>
                                    <h3>500+</h3>
                                    <p>CLients</p>
                                </div>
                                <div>
                                    <h3>70+</h3>
                                    <p>Excursions</p>
                                </div>
                                <div>
                                    <h3>10+</h3>
                                    <p>Experience Years</p>
                                </div>


                            </div>
                        </div>

                        <Search />

                    </div>
                </div>
            </div>
            <div className='background-img-main' >
                <img src={backgroundImg} alt="background-img" className="background-img" />
            </div>
        </main>
    )
}

export default HeroSection