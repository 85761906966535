import React from 'react'

import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

export const ContactApiContext = createContext(null)



function ContactApi(props) {

    const location = useLocation();

    let BASE_URL = process.env.REACT_APP_API_KEY
    let [contactTravels, setContactTravels] = useState([])

    useEffect(() => {


        axios.get(`${BASE_URL}/api/contact`).then((response) => {
            let dataCityTravels = response.data;
            setContactTravels(dataCityTravels);
        });

    }, []);

    return (
        <ContactApiContext.Provider value={{ contactTravels }}>
            {props.children}
        </ContactApiContext.Provider>
    )
}

export default ContactApi