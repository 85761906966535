import React from 'react'

import userSvg from "../../assets/icons/user (2).svg"
import phoneSvg from "../../assets/icons/phone.svg"
import emailSvg from "../../assets/icons/envelope.svg"
import messageSvg from "../../assets/icons/message-dots.svg"



import { useState, useContext } from 'react'

import TittleSection from '../../components/TittleSection'


import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import axios from "axios"


import ErrorAlert from '../../components/alertCopm/ErrorAlert'
import SuccessAlert from '../../components/alertCopm/SuccessAlert'





function ContactUs() {




    let BASE_URL = process.env.REACT_APP_API_KEY



    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [gmail, setGmail] = useState("")
    const [message, setMessage] = useState("")


    let [errorAlertMessage, setErrorAlertMessage] = useState("")
    let [successAlertMessage, setSuccessAlertMessage] = useState("")
    let [toggleAlertError, setToggleAlertError] = useState(false)
    let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)



    let senMessageFunction = (e) => {
        e.preventDefault()
        axios.post(`${BASE_URL}/api/message`, { name, phone, gmail, message }).then(response => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)
                setTimeout(() => {
                    setToggleAlertSucsses(false)
                }, 5000)
                setName("")
                setPhone("")
                setGmail("")
                setMessage("")
            }
        })
    }

    let TitlePageObject = {
        title: "Contact",
        subTittle: "Trending Travel in  2023",
        description: "Contact us for inquiries, assistance, or to start planning your next adventure. Our dedicated team is ready to help you with any questions you may have. Reach out and let's make your travel dreams a reality!"
    }


    return (

        <>
            
            <section className="section-contact-form" >

                <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
                <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />
                <div id='contact-us'>

                    <div className='container' >
                        <TittleSection TitlePageObject={TitlePageObject} />
                        <div className='form-container'>
                            <form onSubmit={(e) => senMessageFunction(e)}>

                                <div className='name-phone-div' >
                                    <div className='div-img-input'  >
                                        <img src={userSvg} alt='company-icon' />
                                        <input type="text" placeholder="Your Name" required value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className='div-img-input div-img-input-phone'  >
                                        <img src={phoneSvg} alt='company-icon' />

                                        <PhoneInput country={'ca'} enableSearch={true} required value={phone} onChange={(phone) => setPhone(phone)} />
                                    </div>
                                </div>

                                <div className='div-img-input'  >
                                    <img src={emailSvg} alt='company-icon' />
                                    <input type="email" placeholder="Your Email" required value={gmail} onChange={(e) => setGmail(e.target.value)} />
                                </div>

                                <div className='div-img-input textarea-div'  >
                                    <img src={messageSvg} alt='company-icon' />
                                    <textarea placeholder="Your Message" required value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                                </div>

                                <button type="submit" className='main-button'   >Get Started </button>
                            </form>
                        </div>

                    </div>
                </div>


            </section>

           
        </>
    )
}

export default ContactUs