import React, { useState, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import ReactPaginate from 'react-paginate';

import { GalleryApiContext } from '../Api/Gallery';

function Gallery() {
    const { gallery } = useContext(GalleryApiContext);

    const itemsPerPage = 9;

    const pageCount = Math.ceil(gallery.length / itemsPerPage);

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const paginatedGallery = gallery.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const photos = paginatedGallery.map((photo) => ({ src: photo, width: 800, height: 600 }));

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <>
            <Header />
            <div className='gallery-page first-section'>
                <div className='container'>
                    <PhotoGallery
                        photos={photos}
                        onClick={(e, { photo, index }) => openLightbox(index)}
                    />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map((x) => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>

                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
            containerClassName={'pagination-buttons'}
            activeClassName={'active buttons-pagination'}
            previousClassName={'button-toggle'}
            nextClassName={'button-toggle'}
                    />
                </div>
            </div>
            <Footer />

        </>
    );
}

export default Gallery;
