import React from 'react'
import './../style/Style.css';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { useCookies } from 'react-cookie';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ErrorAlert from '../../components/alertCopm/ErrorAlert';

function Login() {

    let BASE_URL = process.env.REACT_APP_API_KEY
    const navigate = useNavigate();

    const [cookies, setCookie] = useCookies(['access_token']);
    const [cookiesUser, setCookieUser ] = useCookies(['access_token_User']);

    let [errorAlertMessage, setErrorAlertMessage] = useState("")
    let [toggleAlertError, setToggleAlertError] = useState(false)
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")


    let sendDataForm = (e) => {
        e.preventDefault()
        axios.post(`${BASE_URL}/api/login`, { username, password }).then((response) => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {

                setCookieUser('access_token_User', '');
                
                setCookie('access_token', response.data.token)
                window.localStorage.setItem("adminId", response.data.adminId)
                navigate(`/admin/projects`)
            }
        })
    }




    return (
        <div className="container-login">
              <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
            
            <div className="card-data">
                <Link to="/">
                    <h1 className="h1-text">Elite Travel</h1>
                </Link>
                <div className="container-card">
                    <form action="" className="form-register form-login" onSubmit={sendDataForm}>
                        <div className="register-up-div">
                            <input type="text" placeholder="Email" required value={username} onChange={(e) => setUsername(e.target.value)} />
                            <input type="password" placeholder="Password" required value={password}  onChange={(e) => setPassword(e.target.value)}/>
                            <button className="button-form"> Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login