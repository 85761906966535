import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import { useState, useEffect, useRef } from 'react';
import 'swiper/css';

import axios from "axios"

import star from "../assets/icons/Star.svg"

import TittleSection from '../components/TittleSection'


function ReviewSec() {

    let TitlePageObject = {
        title: "Review",
        subTittle: "Our Reviews",
        description: "Discover customer experiences in Our Reviews, capturing the essence of their journeys. Join the global conversation and find out why our service is celebrated worldwide."

    }


    // get data
    // const [data, setData] = useState([])


    // useEffect(() => {
    //     axios.get("http://localhost:9600/api/review").then((response) => {
    //         setData(response.data)
    //     })
    // }, [])

    return (
        <div>
            <div className="container">
                <TittleSection TitlePageObject={TitlePageObject} />

                <div className="section-7 section" id="section-7">

                </div>
                <div className='review-grid' >
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        breakpoints={{
                            50: {
                                slidesPerView: 1,
                            },
                            576: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 2,
                            },
                            1500: {
                                slidesPerView: 3,
                            },
                        }}
                    >


                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Lucza Brigitta </h2>
                                        <p>From Romania</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    Thank you Bassam Elkady and Elite travel
                                    agency for this beautiful and unforgetable
                                    experience. Very serious agency and
                                    correct prices. I highly recomend this!                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Dennis Will</h2>
                                        <p>From Germany</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    super touren alle freundlich mega preise alles
                                    super nur zu empfehlen eta immer wieder                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Stefanie Lorenz</h2>
                                        <p>From Germany</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    Sehr netter Kontakt mit der ETA! Beste Preise
                                    in ganz Hurghada! Super Ausflug mit top
                                    Betreuung und superfreundlicher, gut gelaunter
                                    und fürsorglicher Crew an Bord, beim Ausflug
                                    und beim Schnorcheln! 10 von 10 Punkte !!!
                                    Definitiv immer wieder! Thank you !!!

                                    Rundumbetreuung und sehr kinderfreundlich!!

                                    1. Ausflug Orange Bay
                                    2. Ausflug Sea Scope
                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Vasiu Adela</h2>
                                        <p>From Romania</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    Thank you Bassam Elkady and Elite travel
                                    agency for this beautiful and unforgetable
                                    experience. Very serious agency and very good prices. I highly recomend this!                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Aziz ArmstrongKatie</h2>
                                        <p>From Moscow</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    An amazing company that helped us have the
                                    best time in Hurghada and Cairo! highly
                                    recommend this company, plus the prices are
                                    very reasonable. Can't wait to come back and
                                    use you again for our trips                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Xriss Topher</h2>
                                        <p>From United Kingdom</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    awesome day out swimming with the turtles,
                                    even saw a pod of dolphins and a sea cow.
                                    many thanks                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Fee Davson</h2>
                                        <p>From United Kingdom</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    We had the best of days swimming with
                                    Turtles, seeing the sea cows and the wild
                                    dolphins. From contacting for info to sadly
                                    being dropped to the hotel everything was
                                    amazing. A huge shout out to a great crew on
                                    the boat, delicious lunch and a lovely private
                                    driver. 5 star all the way. Can't wait to come
                                    back again. Thanks for helping us make
                                    memories                                </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>

                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Grace Isabella</h2>
                                        <p>From United Kingdom</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    If your are looking for trips with someone safe
                                    this guy is your man . He would climb hills and
                                    mountains to make you happy . We had a
                                    fantastic experience for our first time one in
                                    Egypt. And booked so many trip with this guy .
                                    From cardio to Luxor to sea turtles .
                                    He is very friendly and so good with my son
                                    that's he wants to come back every year . He is
                                    open and honest and a professional
                                    photographer for all your best pictures . 100
                                    percent you need to book this guy for your trips                          </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>


                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Aiden Merriman</h2>
                                        <p>From United Kingdom</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    Very good tour guide . We made fantastic
                                    memory as a family together . The tour guide
                                    and drivers were amazing at what they did . We
                                    felt safe and look after the entire time . Thank
                                    you so much for making our family holiday
                                    fantastic                       </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>

                        <SwiperSlide >
                            <div className='card-review '  >
                                <div className='img-name-div' >

                                    <div className='about-reviewer' >
                                        <h2>Neddyax Bndb</h2>
                                        <p>From Netherlands</p>
                                    </div>
                                </div>
                                <p className='reviewer-text'>
                                    Had the best experience with ETA when we
                                    used them in Hurghada. We used their taxi
                                    services and got sim cards from them for a
                                    goos price. They reply very fast on WhatsApp,
                                    have good cars and are on time. They helped
                                    us a lot when we needed a car last minute. I
                                    would really recommend this company and will
                                    definitely book again with them.                 </p>
                                <div className='star-div'>
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                    <img src={star} alt='star' />
                                </div>
                            </div>

                        </SwiperSlide>

                        {/* {
                            data.map((item) => {
                                return (
                                    <SwiperSlide key={item._id}>
                                        <div className='card-review '  >
                                            <div className='img-name-div' >
                                                <div className='img-reviewer'>
                                                    <img src={item.img} />
                                                </div>
                                                <div className='about-reviewer' >
                                                    <h2>{item.name}</h2>
                                                    <p>{item.jop}</p>
                                                </div>
                                            </div>
                                            <p className='reviewer-text'>
                                                {item.message}
                                            </p>
                                            <div className='star-div'>
                                                <img src={star} alt='star' />
                                                <img src={star} alt='star' />
                                                <img src={star} alt='star' />
                                                <img src={star} alt='star' />
                                                <img src={star} alt='star' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })

                        } */}


                    </Swiper>
                </div>
            </div>
        </div >
    )
}

export default ReviewSec