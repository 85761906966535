import React, { useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'


import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';

import { useContext } from 'react';
import { TravelsContext } from "../../../Api/Travels"
import CardProject from './CardProject';


function UpdateDeleteTravel() {
  let BASE_URL = process.env.REACT_APP_API_KEY

  // Update And Delete Travle

  const { travels } = useContext(TravelsContext)


  let [name, setName] = useState("")
  let [palace, setPalace] = useState("")
  let [price, setPrice] = useState("")
  let [discount, setDiscount] = useState("")
  let [timeTravel, setTimeTravel] = useState("")
  let [location, setLocation] = useState("")
  let [categoryPlace, setCategoryPlace] = useState("")
  let [privates, setPrivate] = useState(false)
  let [category, setCategory] = useState("")
  let [images, setImages] = useState([])

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);

    setImages(filesArray);
  };
  let [about, setAbout] = useState("")

  let [errorAlertMessage, setErrorAlertMessage] = useState("")
  let [successAlertMessage, setSuccessAlertMessage] = useState("")
  let [toggleAlertError, setToggleAlertError] = useState(false)
  let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false)

  let postButton = (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('name', name);
    formData.append('palace', palace);
    formData.append('price', price);
    formData.append('discount', discount);
    formData.append('timeTravel', timeTravel);
    formData.append('location', location);
    formData.append('categoryPlace', categoryPlace);
    formData.append('category', category);
    formData.append('privates', privates);
    formData.append('about', about);

    images.forEach((image, index) => {
      formData.append('images', image);
    });

    axios.post(`${BASE_URL}/api/add-new-travel`, formData).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error)
        setToggleAlertError(true)
        setTimeout(() => {
          setToggleAlertError(false)
        }, 5000)
      } else {
        setSuccessAlertMessage(response.data.success)
        setToggleAlertSucsses(true)
        setTimeout(() => {
          setToggleAlertSucsses(false)
        }, 5000)

      }
    });
  }





  const handleDelete = (_id) => {
    axios.delete(`${BASE_URL}/api/travel/${_id}`).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error)
        setToggleAlertError(true)

      } else {
        setSuccessAlertMessage(response.data.success)
        setToggleAlertSucsses(true)

      }
    });
  }






  return (
    <div className='add-new-container add-new-travel' >
      
      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />

      <div className='grid-style'>
        {
          travels.map((travel) => (
            <CardProject travel={travel} key={travel.id} handleDelete={handleDelete} />
          ))
        }
      </div>

    </div>
  )
}

export default UpdateDeleteTravel