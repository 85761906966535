import React from 'react'
import barsSortSvg from "../assets/icons/bars.svg"
import logoSvg from "../assets/logo/logo.svg"

import { Link } from 'react-router-dom'
import { useState } from 'react'


function Header() {


    let [toggle, setToggle] = useState(false)


    let toggleFunction = () => {
        setToggle(!toggle)
    }

    
    let toggleFunc = () => {
        setToggle(false)
    }


    return (

        <header>
            <div className="container">
                <Link to="/" href="index.html" className="a-logo" >
                    <img src={logoSvg} alt="logo.png" />
                </Link>
                <div className="button-div-header">
                    <ul className={toggle ? "list-website active" : "list-website"}>
                    <li className="list-ul-phone" >
                        <Link to="/"  onClick={toggleFunc}  >Home</Link>
                    </li>
                    <li className="list-ul-phone" >
                        <Link to="/about"  onClick={toggleFunc}  >About</Link>
                    </li>
                    <li className="list-ul-phone" >
                        <Link to="/projects"  onClick={toggleFunc}  >Tours</Link>
                    </li>
                    <li className="list-ul-phone" >
                        <Link to="/destination"  onClick={toggleFunc}  >Destination</Link>
                        </li>
                        <li className="list-ul-phone" >
                        <Link to="/gallery"  onClick={toggleFunc}  >Gallery</Link>
                    </li>
                </ul>
                    <Link to="/contact" className='button' onClick={toggleFunc} >Contact Us</Link>
                    <img src={barsSortSvg} alt="bars-sort.svg" className="icon toggle" onClick={() => toggleFunction()} />
                </div>
            </div>
        </header>

    )
}

export default Header