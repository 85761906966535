import React from 'react'


function Card({ data }) {


    let { img, title, description } = data



    return (
        <div className="serv card"  >
            <img alt="img" src={img} className='img-card' />
            <div className="content-serv content-card">
                <h1  >{title}</h1>
                <p  >{description}</p>
            </div>
              
        </div>
    )
}

export default Card