import React from 'react'
import { Link } from 'react-router-dom'


import FacebookIcon from "../assets/icons/Facebook.svg"
import InstagramIcon from "../assets/icons/Instagram.svg"


function Footer() {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                    <div className="row-container">
                        <div className="col-xl-4 col-lg-4 mb-50">
                            <div className="footer-widget">
                                <h1>Elite Travel Agency </h1>
                                <div className="footer-text">
                                    <p>
                                        Elite Travel Agency stands out as a premier provider of tourism trips to Egypt
                                    </p>
                                </div>
                                <div className="footer-social-icon">
                                    <span>Follow Us</span>
                                    <ul className="social-media">
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=100094715590624&mibextid=hIlR13" target="_blank" ><img src={FacebookIcon} alt="icon facebook" className='icon' /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/elitetravelagency__/?igsh=MXJmaGdtZ21wZm5idg%3D%3D" target="_blank" ><img src={InstagramIcon} alt="icon instagram" className='icon' /></a>
                                        </li>
                                    

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Links Website</h3>
                            </div>
                            <ul className="list-footer">
                                <li className="list-ul-phone" >
                                    <Link to="/"  >Home</Link>
                                </li>
                                <li className="list-ul-phone" >
                                    <Link to="/about"  >About</Link>
                                </li>
                                <li className="list-ul-phone" >
                                    <Link to="/projects"  >Tours</Link>
                                </li>
                                <li className="list-ul-phone" >
                                    <Link to="/destination"  >Destination</Link>
                                </li>
                                <li className="list-ul-phone" >
                                    <Link to="/gallery"  >gallery</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Contact Us</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>
                                Connect with us and turn your travel aspirations into reality.
                                </p>
                                <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=eliteetravellagenvyy@gmail.com" target="_blank">eliteetravellagenvyy@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="footer-copy-write">
                        <div>
                            <div className="copyright-text">
                                <p>
                                    Copyright © <span className="year">2023</span> , All Right
                                    Reserved <a href="https://elitetravel-agency.com">elitetravel-agency.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer