import React, { useContext, useState } from 'react';
import ReactPaginate from 'react-paginate';
import CardDestination from '../components/CardDestination';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { TravelsContext } from '../Api/Travels';

function Destination() {
  const { cityTravels } = useContext(TravelsContext);

  const [currentPage, setCurrentPage] = useState(0); // react-paginate uses 0-based index
  const itemsPerPage = 9;

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cityTravels.slice(indexOfFirstItem, indexOfLastItem);
  console.log(currentItems)

  const totalPages = Math.ceil(cityTravels.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <Header />
      <section className='first-section destination-section'>
        <div className='container'>
          <div className='grid-style'>
            {currentItems.map((item, index) => {
              return <CardDestination data={item} key={item._id} />;
            })}
          </div>
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination-buttons'}
            activeClassName={'active buttons-pagination'}
            previousClassName={'button-toggle'}
            nextClassName={'button-toggle'}
          />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Destination;
