import React from 'react'
import arrowIcon from "../../../assets/icons/arrow.svg"
import destinationIcon from "../../../assets/icons/destination.svg"
import { Link } from 'react-router-dom'



function CardDestination({ data , handleDelete }) {


    let { cover, name, _id } = data


    return (
        <div>
            <div className='card-destination' >
                <Link to={`/projects/${name}`}>
                    <img alt='img-destination' className='img-destination' src={cover} />

                    <div className='tittle-div-arrow' >
                        <h2>{name}</h2>
                        <img alt='arrow-icon' className='arrow-icon' src={arrowIcon} />
                    </div>

                   
                </Link>

            </div>
            <div className="update-delete-buttons">
                <button className="delete" onClick={() => handleDelete(_id)}>حذف</button>
            </div>
        </div>
    )
}

export default CardDestination