import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import TravelsContext from "./Api/Travels"
import ContactApiContext from "./Api/ContactApi"
import GalleryApiContext from "./Api/Gallery"

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <BrowserRouter>
    <TravelsContext>
      <ContactApiContext>
        <GalleryApiContext>
          <App />
        </GalleryApiContext>
      </ContactApiContext>
    </TravelsContext>
  </BrowserRouter>
);


