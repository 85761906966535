import React from 'react'
import emailSvg from "../../../../assets/icons/envelope.svg"
import phoneSvg from "../../../../assets/icons/phone.svg"

import userSvg from "../../../../assets/icons/user (2).svg"
import countrySvg from "../../../../assets/icons/Globus.svg"
import starSvg from "../../../../assets/icons/Star.svg"
import numberOfPersonSvg from "../../../../assets/icons/Users Group Two Rounded.svg"
import roomNumberSvg from "../../../../assets/icons/Slider Vertical.svg"

import { useState } from 'react'

import axios from 'axios'


function MessageCardDone({ data, setUpdateUi, setSuccessAlertMessage, setToggleAlertSucsses, setErrorAlertMessage, setToggleAlertError }) {

    let BASE_URL = process.env.REACT_APP_API_KEY

    let { fullName, country, hotelStar, numberOfPerson, roomNumber, phone, emailAddress, message, _id  , nameTravel , locationHref} = data

    let [toggle, setToggle] = useState(false)

    let deleteFuncMessage = () => {
        axios.delete(`${BASE_URL}/api/contact/${_id}`).then((response) => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)
                setTimeout(() => {
                    setToggleAlertSucsses(false)
                }, 5000)
                setUpdateUi(prev => (!prev))
            }
        })
    }

    let updateFuncMessage = () => {
        axios.put(`${BASE_URL}/api/done-contact/${_id}`).then((response) => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)
                setTimeout(() => {
                    setToggleAlertError(false)
                }, 5000)
            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)
                setTimeout(() => {
                    setToggleAlertSucsses(false)
                }, 5000)
                setUpdateUi(prev => (!prev))
            }
        })
    }



    return (
        <>
            <div className="card-div  card-div-message">
                <h2 className="name-of-text-card">{fullName}</h2>
                <div className="p-card-parent" onClick={() => setToggle(!toggle)}>
                    <p className={toggle ? "p-card active" : "p-card"}>{message}</p>
                    <span className="show-more">{toggle ? "show less" : "show more"}</span>
                </div>
                <div className='data-client' >

                    <div className='div-icon-data' >
                        <img src={countrySvg} alt="github.svg" />
                        {country}
                    </div>
                    <div className='div-icon-data' >
                        <img src={starSvg} alt="github.svg" />
                        {hotelStar}
                    </div>
                    <div className='div-icon-data' >
                        <img src={numberOfPersonSvg} alt="github.svg" />
                        {numberOfPerson}
                    </div>
                    <div className='div-icon-data' >
                        <img src={roomNumberSvg} alt="github.svg" />
                        {roomNumber}
                    </div>

                </div>
                <a className='link-tour' href={locationHref} _target="_blank"  >Link Tour : { nameTravel}</a>

                <div>
                    <p>{emailAddress}</p>
                    <p>{phone}</p>
                </div>
                <div className='email-phone-div'>
                    <a target="_blank" href={`https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=${emailAddress}`} className="icon-a-card icon-git-hub">
                        <img src={emailSvg} alt="github.svg" />
                    </a>
                    <a target="_blank" href={`https://wa.me/${phone}`} className="icon-a-card icon-git-hub">
                        <img src={phoneSvg} alt="github.svg" />
                    </a>
                </div>
                <div className='update-delete-buttons' >

                    <div className='delete' onClick={() => deleteFuncMessage()} >
                        حذف
                    </div>
                    <div className='update' onClick={() => updateFuncMessage()} >
                  لم يتم التواصل
                    </div>

                </div>
            </div>
        </>
    )
}

export default MessageCardDone