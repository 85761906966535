import React from 'react'

import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

export const TravelsContext = createContext(null)



function Travels(props) {

    const location = useLocation();
  


    let BASE_URL = process.env.REACT_APP_API_KEY

    let [categoryPlaceString, setCategoryPlaceString] = useState("")


    let [travels, setTravels] = useState([])
    let [travelsHome, setTravelsHome] = useState([])


    let [categoryPlace, setCategoryPlace] = useState([])
    
    let [cityTravels, setCityTravels] = useState([])
    let [contactTravels, setContactTravels] = useState([])

    let [uiUpdate , setUpdateUi] = useState(false)
    console.log(uiUpdate)


    useEffect(() => {
        axios.get(`${BASE_URL}/api/travels`).then((response) => {
            let dataTravels = response.data;
            setTravels(dataTravels);
            setTravelsHome(dataTravels.slice(0, 10));

            let allCategoryPlaces = new Set(dataTravels.reduce((acc, travel) => {
                return acc.concat(travel.categoryPlace);
            }, []));
            let uniqueCategoryPlaces = Array.from(allCategoryPlaces);

            setCategoryPlace(uniqueCategoryPlaces);
        });

        axios.get(`${BASE_URL}/api/city_travels`).then((response) => {
            let dataCityTravels= response.data;
            setCityTravels(dataCityTravels);
        });
        
        axios.get(`${BASE_URL}/api/contact`).then((response) => {
            let dataCityTravels= response.data;
            setContactTravels(dataCityTravels);
        });

    }, [location.pathname , uiUpdate]);


    useEffect(() => {
        axios.get(`${BASE_URL}/api/travels`).then((response) => {
            let dataTravels = response.data;
            if (categoryPlaceString) {
                const categoryPlaceStringLowerCase = categoryPlaceString.toLowerCase();
    
                if (categoryPlaceStringLowerCase === 'all') {
                    setTravels(dataTravels);
                } else {
                    const filteredData = dataTravels.filter(travel => (
                        travel.categoryPlace && travel.categoryPlace.toLowerCase() === categoryPlaceStringLowerCase
                    ));
                    setTravels(filteredData);
                }
            } else {
                setTravels(dataTravels);
            }
        });
    }, [categoryPlaceString]);
    

    return (
        <TravelsContext.Provider value={{ travels, categoryPlace, setCategoryPlaceString , cityTravels , travelsHome , setUpdateUi , uiUpdate }}>
            {props.children}
        </TravelsContext.Provider>
    )
}

export default Travels