import React from 'react'


import Header from '../components/Header';
import Footer from '../components/Footer';
import Achievement from "../components/achievement/Achievement"
import AchievementNew from '../components/AchievementNew';
import WhyUs from "../components/why-us/WhyUs"


function About() {
  return (
    <>
      <Header />

      <section className='first-section about-page' >
        <AchievementNew />
        <Achievement />
        <WhyUs />
      </section>

      <Footer />


    </>
  )
}

export default About