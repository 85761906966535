import React from 'react'

import icon1 from "../../assets/icons/Shield Check.svg"
import icon2 from "../../assets/icons/Star.svg"
import icon3 from "../../assets/icons/Messages.svg"

import TitleSection from '../../components/TitleSection'
import Card from './components/Card'

import { Link } from 'react-router-dom'

function Achievement() {
    let TitleObject = {
        titleSection: "Services",
        titleMain: "The Most important",
        titleGradient: "services we provide",
        description: "With our integrated CRM, project management, collaborationand invoicing capabilities,  you can manageyour business in one secure platform.",
    }
    let objectSection = [
        {
            id: "1",
            img: icon1,
            title: "Transportation",
            description: "From airport transfers to city tours, our transportation services are designed for convenience and comfort.  "
        }, {
            id: "2",
            img: icon2,
            title: "Tailored Tours",
            description: "Explore new horizons with our bespoke tour packages, curated to showcase the best of every destination."
        }, {
            id: "3",
            img: icon3,
            title: "Corporate Solutions",
            description: "Elevate your business travel experience with our corporate services, including efficient logistics and executive transportation.            "
        }
    ]
    return (
        <section>
            <div className="container container-scroll" id='achievement'>
                <TitleSection TitleObject={TitleObject} />
                <div className='grid-style-tow' >
                    <div className="explain-text" >
                        <h2  >
                        Discover Unparalleled Excellence: Elite Travel Agency's Comprehensive Services Tailored to Your Every Desire
                        </h2>
                        <p  >
                            At Elite Travel Agency, we take pride in offering a comprehensive range of services designed to cater to the diverse needs and desires of our esteemed clients. Our commitment to excellence is reflected in the quality and variety of services we provide. Here are some of the most important services that set us apart:

                        </p>
                        <Link className='button' to="/contact">
                            Contact Us
                        </Link>
                    </div>
                    <div className='grid-style-one' >
                        {
                            objectSection.map((item) => {
                                return (
                                    <Card key={item.id} data={item} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Achievement