import React, { useState, useContext } from 'react';


import ReactPaginate from 'react-paginate';

import { GalleryApiContext } from '../../../Api/Gallery';
import axios from 'axios';

import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';


function Gallery() {
    const BASE_URL = process.env.REACT_APP_API_KEY;

    const { gallery } = useContext(GalleryApiContext);

    const itemsPerPage = 9;

    const pageCount = Math.ceil(gallery.length / itemsPerPage);

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const paginatedGallery = gallery.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const photos = paginatedGallery.map((photo) => ({ src: photo, width: 800, height: 600 }));

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const [errorAlertMessage, setErrorAlertMessage] = useState("");
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [toggleAlertError, setToggleAlertError] = useState(false);
    const [toggleAlertSuccess, setToggleAlertSucsses] = useState(false);


    let deleteButton = (index) => {

        axios.delete(`${BASE_URL}/api/gallery/${index}`).then((response) => {
            if (response.data.error) {
                setErrorAlertMessage(response.data.error)
                setToggleAlertError(true)

            } else {
                setSuccessAlertMessage(response.data.success)
                setToggleAlertSucsses(true)

            }
        });
    }


    return (
        <>

            <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
            <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSuccess} />

            <div className='gallery-page first-section'>
                <div className='container'>


                    <div class="img-upload-div">

                        {gallery.map((photo, index) => {
                            return (
                                <div className='img-delete-dash' >
                                    <div class="slide-img-upload">
                                        <img src={photo} alt='' />
                                    </div>
                                    <div class="update-delete-buttons">
                                        <button class="delete" onClick={() => deleteButton(index)} >حذف</button>
                                    </div>
                                </div>
                            )
                        })
                        }



                    </div>


                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination-buttons'}
                        activeClassName={'active buttons-pagination'}
                        previousClassName={'button-toggle'}
                        nextClassName={'button-toggle'}
                    />
                </div>
            </div>
        </>
    );
}

export default Gallery;
