import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';
import { useParams } from 'react-router-dom';

function UpdateTravele() {
  let BASE_URL = process.env.REACT_APP_API_KEY;
  let { id } = useParams();
  
  let [travelDetails, setTravelDetails] = useState({
    _id: "",
    price: "",
    discount: "",
    name: "",
    palace: "",
    about: "",
    category: "",
    categoryPlace: "",
    images: [],
    location: "",
    timeTravel: "",
    privates: false,
    map: "",
  });



  useEffect(() => {
    axios.get(`${BASE_URL}/api/travel/${id}`).then((response) => {
      setTravelDetails(response.data);
    });
  }, [BASE_URL, id]);

  let [nameUpdate, setNameUpdate] = useState("");
  let [palaceUpdate, setPalaceUpdate] = useState("");
  let [priceUpdate, setPriceUpdate] = useState("");
  let [discountUpdate, setDiscountUpdate] = useState("");
  let [timeTravelUpdate, setTimeTravelUpdate] = useState("");
  let [locationUpdate, setLocationUpdate] = useState("");
  let [categoryPlaceUpdate, setCategoryPlaceUpdate] = useState("");
  let [privatesUpdate, setPrivateUpdate] = useState(false);
  let [categoryUpdate, setCategoryUpdate] = useState("");
  let [imagesUpdate, setImagesUpdate] = useState([]);
  let [aboutUpdate, setAboutUpdate] = useState("");

  useEffect(() => {
    setNameUpdate(travelDetails.name);
    setPalaceUpdate(travelDetails.palace);
    setPriceUpdate(travelDetails.price);
    setDiscountUpdate(travelDetails.discount);
    setTimeTravelUpdate(travelDetails.timeTravel);
    setLocationUpdate(travelDetails.location);
    setCategoryPlaceUpdate(travelDetails.categoryPlace);
    setPrivateUpdate(travelDetails.privates);
    setCategoryUpdate(travelDetails.category);
    setImagesUpdate(travelDetails.images);
    setAboutUpdate(travelDetails.about);
  }, [travelDetails]);

  let [errorAlertMessage, setErrorAlertMessage] = useState("");
  let [successAlertMessage, setSuccessAlertMessage] = useState("");
  let [toggleAlertError, setToggleAlertError] = useState(false);
  let [toggleAlertSucsses, setToggleAlertSucsses] = useState(false);

  let handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);
    setImagesUpdate(filesArray);
  };

  let postButton = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', nameUpdate);
    formData.append('palace', palaceUpdate);
    formData.append('price', priceUpdate);
    formData.append('discount', discountUpdate);
    formData.append('timeTravel', timeTravelUpdate);
    formData.append('location', locationUpdate);
    formData.append('categoryPlace', categoryPlaceUpdate);
    formData.append('category', categoryUpdate);
    formData.append('privates', privatesUpdate);
    formData.append('about', aboutUpdate);
  
    if (imagesUpdate.length > 0) {
      imagesUpdate.forEach((image, index) => {
        formData.append('images', image);
      });
    }
  
    axios.put(`${BASE_URL}/api/travel/${id}`, formData).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error);
        setToggleAlertError(true);
        setTimeout(() => {
          setToggleAlertError(false);
        }, 5000);
      } else {
        setSuccessAlertMessage(response.data.success);
        setToggleAlertSucsses(true);
        setTimeout(() => {
          setToggleAlertSucsses(false);
        }, 5000);
      }
    });
  };

  return (
    <div className='add-new-container add-new-travel'>
      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSucsses} />

      <form onSubmit={postButton} encType="multipart/form-data">
        <div className='add-new-tittle'>
          <div className="drag-file-area">
            <span className="material-icons-outlined upload-icon">رفع الصور</span>
            <label className="label">
              <span className="browse-files">
                <input  type="file" name="images" onChange={handleFileChange} multiple className="default-file-input" />
                <span className="browse-files-text">تحميل الصور</span>
                <span>اختار الملافات </span>
              </span>
            </label>
          </div>

          <div className='img-upload-div'>
            {imagesUpdate.map((image, index) => (
              <div key={index} className='slide-img-upload'>
                <img src={image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </div>

        </div>

        <div className='add-new-tittle'>
          <h2>اسم الرحله</h2>
          <input type='text' placeholder='ادخل اسم الرحله' value={nameUpdate} onChange={(e) => setNameUpdate(e.target.value)} />
        </div>
        <div className='add-new-tittle multer-add-new'>
          <div className='input-div'>
            <h2> سعر الرحله</h2>
            <input type='text' placeholder="ادخل سعر الرحله = Euro 50 " value={priceUpdate} onChange={(e) => setPriceUpdate(e.target.value)} />
          </div>
          <div className='input-div'>
            <h2> سعر قبل الخصم</h2>
            <input type='text' placeholder='ادخل سعر الحرحله قبل الخصم' value={discountUpdate} onChange={(e) => setDiscountUpdate(e.target.value)} />
          </div>
        </div>

        <div className='add-new-tittle'>
          <h2> عنوان الرحله</h2>
          <input type='text' placeholder='ادخل عنوان الرحله' value={palaceUpdate} onChange={(e) => setPalaceUpdate(e.target.value)} />
        </div>

        <div className='add-new-tittle'>
          <h2> مده الحرله</h2>
          <input type='text' placeholder='ادخل مده الرحله = 3 Days' value={timeTravelUpdate} onChange={(e) => setTimeTravelUpdate(e.target.value)} />
        </div>

        <div className='add-new-tittle'>
          <h2> رابط عنوان الرحله</h2>
          <input type='text' placeholder='ادخل رابط الرحله علي الـ map' value={locationUpdate} onChange={(e) => setLocationUpdate(e.target.value)} />
        </div>

        <div className='add-new-tittle'>
          <h2> مدينه الرحله</h2>
          <input type='text' placeholder='ادخل مدينه الرحله = Giza' value={categoryPlaceUpdate} onChange={(e) => setCategoryPlaceUpdate(e.target.value)} />
        </div>
        <div className='add-new-tittle'>
          <h2> نوع الرحله</h2>
          <select id="select" value={privatesUpdate} onChange={(e) => setPrivateUpdate(e.target.value)}>
            <option value="false">public</option>
            <option value="true">private</option>
          </select>
        </div>

        <div className='add-new-tittle'>
          <h2> كاتوجري الرحله</h2>
          <input type='text' placeholder='ادخل كاتوجري معبره عن الرحله = pyramids , sand...' value={categoryUpdate} onChange={(e) => setCategoryUpdate(e.target.value)} />
        </div>

        <div className='add-new-tittle'>
          <h2> تفاصيل عن الرحله</h2>
          <ReactQuill value={aboutUpdate} onChange={setAboutUpdate} />
        </div>

        <input className='button' type='submit' value="تحديث" />
      </form>
    </div>
  );
}

export default UpdateTravele;
