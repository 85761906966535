import React from 'react'
import TitleSection from '../../components/TitleSection'
import Card from './components/Card'
import icon from "../../assets/icons/arrow-down.svg"


function WhyUs() {
  let TitleObject = {
    titleSection: "Why Us",
    titleMain: "6 Compelling Reasons to Choose Us",
    titleGradient: "with Elite Travel Agency",
  }
  let objectSection = [
    {
      id: "1",
      icon: icon,
      title: "Expertise in Egyptian Tourism",
      description: "Elite Travel Agency stands out for its in-depth knowledge and expertise in Egyptian tourism. Our team possesses a profound understanding of the country's rich history, vibrant culture, and breathtaking landscapes, ensuring an authentic and enriching travel experience.      "
    }, {
      id: "2",
      icon: icon,
      title: "Tailored Journeys, Personalized Experiences:      ",
      description: "Why choose us? Because at Elite Travel Agency, we go beyond conventional travel. Our commitment to crafting tailored journeys means your experience is unique and personalized, reflecting your preferences and desires while exploring the wonders of Egypt.      "

    }, {
      id: "3",
      icon: icon,
      title: "Exclusive Access to Hidden Gems:  ",
      description: "Unlock the secrets of Egypt with Elite Travel Agency. We pride ourselves on offering exclusive access to hidden gems and off-the-beaten-path destinations. Immerse yourself in the beauty of Egypt beyond the usual tourist routes, creating memories that truly stand out.      "
    }, {
      id: "4",
      icon: icon,
      title: "Unmatched Customer Service:      ",
      description: "Your satisfaction is our top priority. Elite Travel Agency is renowned for its exceptional customer service, providing prompt assistance, addressing inquiries, and ensuring a seamless travel experience. Trust us to make your journey to Egypt smooth, enjoyable, and worry-free.      "
    }, {
      id: "5",
      icon: icon,
      title: "Strategic Partnerships for Premium Experiences:      ",
      description: "Benefit from our strategic partnerships with top-notch hotels, airlines, and local service providers. Elite Travel Agency secures exclusive privileges and premium experiences, elevating your stay in Egypt to a luxurious and unforgettable level.      "

    }, {
      id: "6",
      icon: icon,
      title: "Commitment to Responsible Tourism:      ",
      description: "Choose Elite Travel Agency for a responsible and sustainable travel experience in Egypt. We are dedicated to preserving the beauty of Egypt's natural and cultural heritage. Join us in supporting local communities and promoting eco-friendly practices while exploring this mesmerizing destination.      "
    },
  ]

  return (
    <section>
      <div className="container container-scroll" id='why-us'>
        <TitleSection TitleObject={TitleObject} />
        <div className="grid-why-us">
          {objectSection.map((item) => {
            return (<Card key={item.id} data={item} />)
          })}
        </div>
      </div>
    </section>
  )
}

export default WhyUs