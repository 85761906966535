import React from 'react'
import { useState } from 'react';
import Select from 'react-select';

import iconMapIcon from "../assets/icons/MapPoint.svg"
import dollarIcon from "../assets/icons/Dollar.svg"
import userIcon from "../assets/icons/User.svg"
import searchIcon from "../assets/icons/search.svg"

import { useContext, useEffect } from 'react';

import { TravelsContext } from "../Api/Travels"

import { Link } from 'react-router-dom';

function Search() {

    const { categoryPlace, setCategoryPlaceString } = useContext(TravelsContext)
  


    const [selectedOption, setSelectedOption] = useState(null);

    
    const optionsPlace = categoryPlace.map(place => ({
        value: place,
        label: place,
    }));
    optionsPlace.unshift({ value: 'All', label: 'All' });

 
    return (
        <div className='search-div' >

            <div className='select-div-parent' >
                <div className='div-select'>
                    <div className='icon-select'>
                        <img src={iconMapIcon} alt='icon-map' />
                        <p>Place Locations</p>
                    </div>
                    <Select
                        defaultValue={selectedOption}
                        onChange={(e) => setSelectedOption(e.value)}
                        options={optionsPlace}
                        placeholder="Select Place"
                    />
                </div>
            
            </div>
            <Link className='search-button' to={`/projects/${selectedOption}`} >
                <img src={searchIcon} alt='icon-map' />
            </Link>


        </div>
    )
}

export default Search