import React from 'react'

function TitleSection({ TitleObject }) {


    let { titleSection, titleMain, titleGradient } = TitleObject
    return (
        <div>

            <div className="title-sec new-title-sec">
                <h1  >
                    {titleSection}
                </h1>
                <h3 >{titleMain} <span className='gradient-span' >{titleGradient}</span></h3>
               
            </div>

        </div>
    )
}

export default TitleSection