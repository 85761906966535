import React from 'react'

import whatsappSvgIcon from "../assets/icons/whatsapp.svg"



function WhatsappIconButton() {
    return (
        <div className='icon-contact'>
            <div className='icon-whatsapp'>

                <a href='https://wa.me/201118554084' target="_blank">
                    <img src={whatsappSvgIcon} alt='whatsapp Svg Icon' />
                </a>

            </div >
          
        </div>
    )
}

export default WhatsappIconButton