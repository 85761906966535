import React, { useState, useContext } from 'react';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import SuccessAlert from '../../../components/alertCopm/SuccessAlert';
import ErrorAlert from '../../../components/alertCopm/ErrorAlert';

import Select from 'react-select';
import { TravelsContext } from "../../../Api/Travels"

import CardDestination from './CardDestination';

function AddCityTravels() {
  const BASE_URL = process.env.REACT_APP_API_KEY;



  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [toggleAlertError, setToggleAlertError] = useState(false);
  const [toggleAlertSuccess, setToggleAlertSucsses] = useState(false);


  const { cityTravels , setUpdateUi , uiUpdate } = useContext(TravelsContext)
 
  
  const handleDelete = (_id) => {
    axios.delete(`${BASE_URL}/api/city-travels/${_id}`).then((response) => {
      if (response.data.error) {
        setErrorAlertMessage(response.data.error)
        setToggleAlertError(true)

      } else {
        setSuccessAlertMessage(response.data.success)
        setToggleAlertSucsses(true)
        setUpdateUi(!uiUpdate)
      }
    });
  }


  return (
    <div className='add-new-container add-new-travel'>
      <ErrorAlert AlertMessage={errorAlertMessage} toggleAlert={toggleAlertError} />
      <SuccessAlert AlertMessage={successAlertMessage} toggleAlert={toggleAlertSuccess} />

      <div className='container' >
        <div className='grid-style' >
          {
            cityTravels.map((item) => (
              <CardDestination data={item} handleDelete={handleDelete} />
            ))
          }
        </div>
      </div>

    </div>
  );
}

export default AddCityTravels;
