import React from 'react'

import HeroSection from '../sections/hero-section/HeroSection';
import ProjectSection from '../sections/project-section/ProjectSection';
import About from '../sections/about-section/About';
import Destination from '../sections/destination-section/Destination';
import ContactUs from '../sections/contact-us-section/ContactUs';
import ReviewSec from "../sections/ReviewSec"

import Header from '../components/Header';
import Footer from '../components/Footer';

function Home() {
  return (
    <div>
      <Header />
      <HeroSection />
      <ProjectSection />
      <About />
      <Destination />
      <ReviewSec />
      <ContactUs />
      <Footer />
    </div >
  )
}

export default Home